import { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import moment, { Moment } from "moment";
import { EyeOutlined, CloseOutlined } from "@ant-design/icons";

import { IOrder } from "../../../redux/@types";
import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { ordersService, orderStatusService } from "../../../shared/services";
import { decimal } from "../../../shared/utils/Helper";
import { useTableOrders } from "../hooks/useTableOrders";
import { SimpleInputData } from "../../../shared/components";

const { Title } = Typography;

export interface IDataStatus {
  id: number;
  name: string;
  description: string;
  status_code: string;
  color: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

const initialVal = {
  startDate: null,
  endDate: null,
};

interface TableOrdersProps {
  onDateChange: (dates: { startDate: string | null; endDate: string | null }) => void;
}

export const TableOrders = ({ onDateChange }: TableOrdersProps) => {
  // const [tableProps, setTableProps] = useState<TableProperties>()
  // const tes = ()=>{
  //   tableProps?.refresh()
  // }
  const [orderStatus, setOrderStatus] = useState<IDataStatus[]>([]);
  const [formState, setFormState] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>(initialVal);
  const { endDate, startDate } = formState;
  const getOrderStatus = async () => {
    await orderStatusService
      .find({ query: { $limit: 9999999999999 } })
      .then((res) => {
        setOrderStatus(res.data);
      });
  };

  useEffect(() => {
    getOrderStatus();
  }, []);

  const { changeOrderForUpdate } = useTableOrders();
  const columns: TableColumns<IOrder> = [
    {
      title: "ID",
      width: 5,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Usuario",
      width: 11,
      key: "full_name",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {`${item.user.first_name} ${item.user.last_name}`}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Email",
      width: 12,
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: "Estado",
      width: 15,
      dataIndex: ["status", "status_code"],
      key: "status_code",
      render: (_, item) => {
        // console.log('c===>',item.id,item.status.name)
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {orderStatus && (
              <Col
                style={{
                  display: "flex",
                  padding: "5px 27px",
                  borderRadius: "20px",
                  backgroundColor: orderStatus?.find(
                    (order) => order.id === item.order_status_id
                  )?.color,
                }}
              >
                <Title
                  style={{
                    marginBottom: "1.5px",
                    color: "white",
                  }}
                  level={5}
                >
                  {item.status.name}
                </Title>
              </Col>
            )}
          </Col>
        );
      },
    },
    {
      title: "Método de pago",
      width: 6,
      dataIndex: "payment_method",
      key: "payment_method",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.payment_method === "online"
                ? `Online`
                : item?.payment_method === "pay_in_store"
                ? `Pago en la tienda`
                : null}
            </Title>
          </Col>
        );
      },
      filters: [
        {
          text: "Pago en la tienda",
          value: "pay_in_store",
        },
        {
          text: "Online",
          value: "online",
        },
      ],
    },
    {
      title: "Total",
      width: 7,
      dataIndex: "total_price",
      key: "total_price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.total_price)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Pago recibido",
      width: 7,
      dataIndex: "total_price",
      key: "total_price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.total_payment_received)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Pago pendiente",
      width: 7,
      dataIndex: "total_price",
      key: "total_price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.total_payment_pending)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Fecha",
      width: 7,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {moment(item.createdAt).add("h", 5).format("DD/MM/YYYY")}
            </Title>
          </Col>
        );
      },
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 5,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeOrderForUpdate(item)}
              icon={<EyeOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  useEffect(() => {
    onDateChange({ 
      startDate: startDate ? (typeof startDate === 'string' ? startDate : moment(startDate).format('YYYY-MM-DD')) : null,
      endDate: endDate ? (typeof endDate === 'string' ? endDate : moment(endDate).format('YYYY-MM-DD')) : null
    });
  }, [startDate, endDate, onDateChange]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Row
        gutter={[8, 8]}
        style={{ display: "flex", flexDirection: "row", width: "100%" }}
      >
        <Col span={6}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Fecha de Inicio:
          </Title>
          <SimpleInputData
            placeHolder="YYYY-MM-DD"
            value={startDate}
            name="startDate"
            onChangeDate={(val: Moment) => {
              setFormState({
                ...formState,
                startDate: val.format("YYYY-MM-DD"),
              });
            }}
          />
        </Col>
        <Col span={6}>
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Fecha de Limite:
          </Title>
          <SimpleInputData
            placeHolder="YYYY-MM-DD"
            value={endDate}
            name="startDate"
            onChangeDate={(val: Moment) => {
              setFormState({
                ...formState,
                endDate: val.format("YYYY-MM-DD"),
              });
            }}
          />
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            paddingTop: "8px",
          }}
        >
          <Button
            type="primary"
            onClick={() => setFormState(initialVal)}
            shape="circle"
            icon={<CloseOutlined />}
            size="large"
            danger
          />
        </Col>
      </Row>
      <Table<IOrder>
        columns={columns}
        fetchQueryProps={{
          $sort: { id: -1 },
        }}
        fetch$ClientProps={{
          startDate,
          endDate,
        }}
        service={ordersService}
        //onLoad={setTableProps}
      />
    </div>
  );
};
