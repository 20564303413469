import { Button, Col, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";

import { AdminMenuLayout } from "../../../shared/components";
import { WrapperOrders } from "./styled";
import { TableOrders } from "./TableOrders";
import { ordersService } from "../../../shared/services";

export const Orders = () => {
  const [dateRange, setDateRange] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({ startDate: null, endDate: null });

  const handleDownload = async () => {
    const query: any = { 
      $client: { 
        exportData: "true",
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      } 
    };

    await ordersService
      .find({ query })
      .then((res) => {
        window.open(res.data[0].objectUrl, "_blank");
      });
  };

  return (
    <AdminMenuLayout
      pageDescription="ProductCategories"
      title="ProductCategories"
    >
      <WrapperOrders>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              style={{ marginRight: "1rem" }}
              onClick={handleDownload}
            >
              Descargar listado de pedidos
            </Button>
          </Col>
        </Row>
        <TableOrders onDateChange={setDateRange} />
      </WrapperOrders>
    </AdminMenuLayout>
  );
};
